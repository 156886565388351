.publishRootContainer {
  .leftContainer {
    .pictureContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      background-position: center;
      background-size: cover;
    }

    .uploadBtn {
      width: 108px;
      height: 36px;
      border-radius: 3px;
      cursor: pointer;
      letter-spacing: 0.07em;
      font-size: 14px;
      outline: none;
    }

    button {
      opacity: 1;

      button:hover {
        opacity: 0.6;
      }
    }
  }
}

@media only screen and (max-width: 900px) {
  .pictureContainer {
    margin: 0 auto;
  }
}
