input[type="text"] {
  border: none;
  outline: none;
  width: 100%;
  background: none;

  &::placeholder {
    color: $grey;
  }

  &:focus,
  &:hover,
  &:valid,
  &:invalid {
    border: none;
    outline: none;
  }
}

.workflowSettings {
  .radioContainer {
    margin-bottom: 10px;
  }
}

.radioContainer {
  color: $black;
  font-family: $font;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  border: none;
  background: none;
  cursor: pointer;

  .radio,
  .radioChecked,
  .radioCheckedDisabled {
    border: 2px solid $grey;
    width: 18px;
    height: 18px;
    position: absolute;
    cursor: pointer;
  }

  .radio {
    border-color: $grey;
  }

  .radioChecked {
    border-color: $blue;
    background-color: $blue;

    &:after {
      content: "";
      width: 8px;
      height: 4px;
      position: absolute;
      top: 4px;
      left: 3px;
      border: 3px solid #fcfff4;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
    }

    &:hover::after {
      opacity: 0.3;
    }
  }

  .radioCheckedDisabled {
    border-color: $grey;
    background-color: $grey;

    &:after {
      content: "";
      width: 8px;
      height: 4px;
      position: absolute;
      top: 4px;
      left: 3px;
      border: 3px solid #fcfff4;
      border-top: none;
      border-right: none;
      background: transparent;
      opacity: 0;
      transform: rotate(-45deg);
    }

    &:hover::after {
      opacity: 0.3;
    }
  }

  input[type="checkbox"] {
    visibility: hidden;

    &:checked + label:after {
      opacity: 1;
    }
  }

  span {
    margin-left: 10px;
    padding-top: 3px;
  }

  &:hover {
    .radio {
      border-color: rgb(70, 142, 227);
    }
  }
}

textarea {
  color: $black;
  border: none;
  outline: none;
  background: none;
  width: 100%;

  &::placeholder {
    color: $grey;
  }
}

textarea:focus,
textarea:hover {
  border: none;
  outline: none;
  color: $grey;
}

input[type="submit"] {
  border: none;
  outline: none;
  color: $grey;
}

Select {
  width: 100%;
  border: none;
  outline: none;
  color: $grey_light;
  font-size: 1em;
  font-family: $font;
  font-weight: 500;
  padding: 8px 10px;
  border-radius: 0.2em;
  margin-top: 15px;

  &:focus {
    border: none;
  }
}

.labelContainer,
.CountryContainer,
.BRContainer {
  margin-bottom: 25px;
}

.FixedTagsContainer {
  margin-bottom: 25px;

  .TagsContainer {
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .framedGrey {
      font-size: 0.9em;
      font-family: $font;
      font-weight: 400;
      padding: 8px 10px;
      margin-right: 5px;
      border-radius: 0.2em;
      color: $white;
      background-color: $grey_light;
    }
  }
}

.BrandContainer,
.RetailerContainer {
  width: 48%;
  display: inline-block;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.BrandContainer {
  padding-right: 2%;
}

.RetailerContainer {
  padding-left: 2%;
}

.label {
  font-size: 16px;
  text-align: left;
  font-family: $font;
  font-weight: 400;
  color: $black;
  margin-bottom: 15px;
}

.DropdownContainer {
  width: 100%;
  font-size: 14px;
  color: $black;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
}

.TagDropdownContainer {
  width: 100%;
  border-radius: 2px;
  padding-bottom: 4px;
}

.postingDate {
  display: flex;
  width: 100%;
  margin-top: 45px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.postingDateButton {
  padding-right: 10px;
}

.sectionUserCategory {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
