$red_light: #fec5bb;
$red_medium: #fcd5ce;
$red_dark: #fae1dd;

$green_light: #e8e8e4;
$green_medium: #ece4db;
$green_dark: #d8e2dc;

$yellow_light: #ffe5d9;
$yellow_medium: #ffd7ba;
$yellow_dark: #fec89a;

.publishContainer {
  margin: 100px auto;
  max-width: 950px;
  width: 100%;
  display: grid;
  grid-gap: 0.5rem 5rem;
  grid-template-columns: 1fr 1fr 1fr;

  .titleContainer {
    font-weight: bold;
    grid-column: span 3;

    textarea {
      color: $black;
      font-family: $font;
      font-size: 22px;
      font-weight: 600;
    }
  }

  .sumContainer {
    grid-column: span 3;

    textarea {
      color: $black;
      font-family: $font;
      font-size: 16px;
      font-weight: 300;
    }
  }
  .leftContainer {
    width: 100%;
    grid-column: 1;
  }

  .rightContainer {
    width: 100%;
    grid-column: span 2;
  }

  .close {
    position: absolute;
    right: 6em;
    top: 6em;
    width: 32px;
    height: 32px;
    border: 0;
    background-color: transparent;
    cursor: pointer;

    &:nth-child(1):before,
    &:nth-child(1):after {
      content: " ";
      position: absolute;
      top: -0.2em;
      left: 1em;
      height: 33px;
      width: 2px;
      opacity: 0.6;
      background-color: $grey;
    }

    &:nth-child(1):before {
      transform: rotate(45deg);
    }

    &:nth-child(1):after {
      transform: rotate(-45deg);
    }
  }

  .privacyContainer {
    display: inline-flex;
    flex-direction: row-reverse;
    width: 100%;
  }
}

.undefined {
  font-family: $font;
  font-size: 12px;
  color: $red_medium;
}

@media only screen and (max-width: 1280px) {
  .publishContainer {
    max-width: 800px;
    grid-gap: 0.5rem;
    grid-template-columns: 1fr 1fr;

    .close {
      right: 5em;
      top: 5em;
      width: 25px;
      height: 25px;

      &:nth-child(1):before,
      &:nth-child(1):after {
        height: 26px;
      }
    }

    .rightContainer {
      padding-left: 0%;
      width: 100%;
    }
  }
}

@media only screen and (max-width: 900px) {
  .publishContainer {
    max-width: 400px;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0.5rem;

    .titleContainer,
    .sumContainer,
    .leftContainer,
    .rightContainer {
      grid-column: span 1;
    }

    .close {
      right: 5em;
      top: 5em;
      width: 25px;
      height: 25px;

      &:nth-child(1):before,
      &:nth-child(1):after {
        height: 26px;
      }
    }
  }
}
