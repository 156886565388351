// @import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,&family=Roboto:ital,&display=swap');
/* @import must be at top of file, otherwise CSS will not work */

@font-face {
  font-family: "FuturaPTBook";
  src:
    local("FuturaPTBook"),
    url(./assets/fonts/FuturaPTBook.otf) format("opentype");
}

@font-face {
  font-family: "URWDINCond-Demi";
  src:
    local("URWDINCond-Demi"),
    url(./assets/fonts/URWDINCond-Demi.ttf) format("truetype");
}

$font: "Roboto", sans-serif;
$fontCondensed: "Roboto Condensed", sans-serif;
$white: #ffffff;
$black: #252525;
$grey: #7c7c7c;
$grey_light: #fcfff4;
$blue: rgb(70, 142, 227);
$backgroundColor: #f4f4f4;

body {
  font-family: $font;
  font-size: 14px;
  line-height: 1;
  overflow-x: visible;
  overflow-y: scroll;
  width: 100%;
  height: 100vh;
  background-color: $backgroundColor;
}

body,
#root {
  min-height: 100vh;
  min-width: 100%;
}

* {
  font-variant-ligatures: none;
}

@mixin downTo($size) {
  @media (max-width: $size) {
    @content;
  }
}
