.clientContainer {
  width: 100%;
  margin-bottom: 100px;

  .privacyContainer {
    right: 0;
    width: 50%;
    display: inline-flex;
    margin-bottom: 15px;

    .label {
      margin-right: 10px;
      color: #000;
      font-family: $font;
      font-size: 1.2em;
      font-weight: 600;
    }
  }

  .languageContainer {
    width: 50%;
    display: inline-flex;
  }

  .titleContainer,
  .sumContainer {
    width: 100%;
    color: $grey;
  }

  .overviewContainer {
    margin-bottom: 30px;

    .radioContainer {
      span {
        font-size: 1.1em;
        font-weight: 500;
        margin-left: 20px;
      }
    }

    .overviewSimulation {
      width: 100%;
      margin-top: 30px;

      .simulationView {
        width: 100%;
        height: 120px;
        margin-top: 10px;
        display: flex;

        .img {
          width: 30%;
          height: 120px;
          display: flex;
          align-items: center;
          flex-direction: column;
          background-size: cover;
          justify-content: flex-start;
          background-repeat: no-repeat;
          background-position: center center;

          .uploadBtn {
            margin-top: 50px;
          }
        }

        textarea {
          width: 70%;
          height: 90px;
          font-family: $font;
          font-weight: 400;
          color: $grey;
          font-size: 1em;
          letter-spacing: 0.05em;
          font-stretch: condensed;
          background-color: $white;
          padding: 15px;
        }
      }
    }
  }

  .workflowSettings {
    margin-top: 15px;
  }

  .postingDateSwitchers {
    display: flex;
    align-items: flex-end;
    flex-direction: row;
    justify-content: space-between;
  }

  .startPostingDate,
  .endPostingDate {
    margin: 0px;
    text-align: center;

    span {
      width: 100%;
    }
  }

  .noEndPostingDate {
    padding: 6px 2px;
    text-align: center;

    span {
      font-size: 14px;
      color: $grey;
    }
  }

  .PostingDateSave {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 1280px) {
  .clientContainer {
    .overviewContainer {
      .overviewSimulation {
        .simulationView {
          width: 100%;
          height: 140px;

          .img {
            width: 40%;
            height: 140px;

            .uploadBtn {
              margin-top: 60px;
            }
          }

          textarea {
            width: 60%;
            height: 110px;
          }
        }
      }
    }

    .PostingDate {
      border-radius: 3px;
      padding-bottom: 25px;
      margin-top: 20px;

      .postingDateSwitchers {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 0.5rem;

        .startPostingDate,
        .endPostingDate {
          margin: 0 10px;
          grid-column: span 1;

          span {
            font-size: 14px;
            text-align: left;
          }
        }

        .noEndPostingDate {
          grid-column: span 2;
          margin-top: 20px;

          button {
            margin: 0 auto;
          }
        }
      }
    }

    .PostingDateSave {
      margin-top: 20px;
    }
  }
}
